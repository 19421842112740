import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import PaymentAmount from "./PaymentAmount";
import {selectEventConfig} from "../../event_fetcher/EventFetcherSlice";
import Przelewy24Form from "./Przelewy24Form";
import ReactGA from "react-ga";

const PaymentChooser = ({sender, letterId, backgroundIsSpecial}) => {
    const [selectedAmount, setSelectedAmount] = useState(false);
    const [customAmount, setCustomAmount] = useState("");
    const {event} = useSelector(selectEventConfig);


    if (!sender) {
        sender = {
            first_name: "Test First Name",
            last_name: "Test Last Name",
            email: "m.mojek@gmail.com",
            postal_code: "00-175",
        };
    }

    // : {
    //   payment_account,
    //   payament_amount_one,
    //   payament_amount_two,
    //   minimum_amount,
    // },
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        formState: {errors},
    } = useForm({
        defaultValues: {
            description: event && event.payment_account,
            firstname: sender.first_name,
            lastname: sender.last_name,
            email: sender.email,
            amountChosen: undefined,
            // postcode: sender.postal_code,
            source: "santa_letter",
            // ignore_last_payment_channel: 1,
            // api_version: "dev",
            // currency: "PLN",
            success_url: `${process.env.REACT_APP_DOMAIN}/dziekujemy/l/${letterId}/amt/0`,
            // urlc: `${process.env.REACT_APP_API_DOMAIN}/urlc`,
            // type: 4,
            product_id: letterId,
        },
    });
    const paymentOne = backgroundIsSpecial ? event.payament_amount_one_special : event.payament_amount_one;
    const paymentTwo = backgroundIsSpecial ? event.payament_amount_two_special : event.payament_amount_two;
    const minimumAmount = backgroundIsSpecial ? event.minimum_amount_special : event.minimum_amount;

    useEffect(() => {
        if (event) {
            register("amount", {required: true, min: minimumAmount});
        }
    }, [event]);

    const updateUrlAfterAmountSet = (amount) => {
        const amountRegex = /amt\/[0-9.]*/gm;
        const url = getValues("success_url");
        const urlUpdated = url.replace(amountRegex, "amt/" + amount);
        setValue("success_url", urlUpdated);
    };
    const handleSelectPayment = (amountVal) => {
        setSelectedAmount(amountVal);
        setValue("amount", "");
        console.log("amountVal", amountVal);
        if (amountVal !== "other") {
            setValue("amount", amountVal, {shouldValidate: true});
            let amountChosen
            if (amountVal === "20") amountChosen = 1
            if (amountVal === "35") amountChosen = 2

            setValue("amountChosen", amountChosen);
            ReactGA.event({
                category: 'Wyślij list',
                action: "Przekaż darowiznę i czekaj na list",
                label: 'Kwota: ' + amountChosen,
                value: +amountVal
            });
        } else {
            setValue("amountChosen", 3);
            if (customAmount) {
                setValue("amount", customAmount, {shouldValidate: true});

            }
        }
        updateUrlAfterAmountSet(getValues("amount"));
    };
    const handleOther = (e) => {
        // const amountPreparation = e.target.value.replace(",", ".");
        const amountPreparation = e.target.value;
        setValue("amount", e.target.value, {
            shouldDirty: true,
            shouldValidate: true,
        });
        setCustomAmount(e.target.value);
        updateUrlAfterAmountSet(getValues("amount"));
    };

    const price_info = backgroundIsSpecial ? {
        error_info: <div className="mb-4">Przygotowanie i wysyłka tego listu to koszt około 20 zł. Sugerujemy jednak przekazanie darowizny w wysokości {minimumAmount} złotych lub więcej. Każda złotówka jest przeznaczana na pomoc dzieciom, które zostały skrzywdzone i bardzo tej pomocy potrzebują. </div>,
        info_one: <span>wspiera edukację dzieci w zakresie bezpiecznego korzystania z&nbsp;Internetu</span>,
        info_two: <span>wspiera terapię dziecka, które doświadczyło przemocy</span>,
        info_three: <span> Każda darowizna pozwoli <br/>
                            nam skutecznie pomagać
                            <br/>
                            krzywdzonym dzieciom.</span>,
    } : {
        error_info: <div className="mb-4">
            Przygotowanie i wysyłka Listu od Mikołaja kosztuje około 15 zł. Sugerujemy jednak przekazanie darowizny w wysokości {minimumAmount} złotych lub więcej. Każda złotówka jest przeznaczana na pomoc dzieciom, które zostały skrzywdzone i bardzo tej pomocy potrzebują. </div>,
        info_one: <span>wspiera przygotowanie dziecka do przesłuchania</span>,
        info_two: <span>wspiera edukację dzieci w zakresie bezpiecznego korzystania z Internetu</span>,
        info_three: <span> Każda darowizna pozwoli <br/>
                            nam skutecznie pomagać
                            <br/>
                            krzywdzonym dzieciom.</span>,
    };
    if (!event) {
        return "czekaj....";
    }


    return (
        <div className="container-fluid dotation-container">
            <div className="container text-center">
                <div className="tall ng-scope">
                    <div className="text-center">
                        {errors && errors.amount && price_info.error_info}
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-4 col-xs-3">
                            <PaymentAmount amountVal={paymentOne} selectedAmount={selectedAmount} handleClick={() =>
                                handleSelectPayment(paymentOne.toString())
                            }>
                                {price_info.info_one}
                            </PaymentAmount>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-3">
                            <PaymentAmount amountVal={paymentTwo} selectedAmount={selectedAmount} handleClick={() =>
                                handleSelectPayment(paymentTwo.toString())
                            }>
                                {price_info.info_two}
                            </PaymentAmount>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-6">
                            <PaymentAmount selectedAmount={selectedAmount} amountVal={false}
                                           handleClick={() => handleSelectPayment("other")} handleOther={handleOther}
                                           minimum_amount={minimumAmount}>
                                {price_info.info_three}
                            </PaymentAmount>
                        </div>
                    </div>
                    <div className="hidden-lg hidden-md hidden-sm">
                        <div className="dot-info">
                            Każda darowizna pozwoli nam skutecznie pomagać krzywdzonym
                            dzieciom.
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Przelewy24Form register={register} handleSubmit={handleSubmit} watch={watch}
                                            errors={errors}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentChooser;
