import React, {useRef} from "react";
import {counterSlice} from "../../counter/counterSlice";
import ReactGA from "react-ga";

const Przelewy24Form = ({register, handleSubmit, watch, errors}) => {
  const formDotpay = useRef(null);

  const onSubmit = (data) => {
    console.log(data);
    if (data.amountChosen === 3) {
      ReactGA.event({
        category: 'Wyślij list',
        action: "Przekaż darowiznę i czekaj na list",
        label: 'Kwota: ' + data.amountChosen,
        value: + data.amount
      });
    }
    ReactGA.event({
      category: 'Wyślij list',
      action: "Przekaż darowiznę i czekaj na list",
      label: "Wpłać"
    });

    formDotpay.current && formDotpay.current.submit();

  };

  const watchAllFields = watch();
  // console.log({ watchAllFields });
  // console.log({ errors });

  const inputsHidden = [];

  for (const field in watchAllFields) {
    // console.log(watchAllFields[field]);
    inputsHidden.push(
      <input type="hidden" key={inputsHidden.length} name={field}
             value={watchAllFields[field] ? watchAllFields[field] : ""}/>
    );
  }
  return (
    <>
      <form method="POST" action={`${process.env.REACT_APP_API_DOMAIN}/przelewy24/register-payment-post/`} id="dot_form"
            ref={formDotpay}>
        {inputsHidden}

        {/* <input type="hidden" name="id" value="151499" />
        <input type="hidden" name="kwota" value="18" />
        <input type="hidden" name="opis" value="List od Mikołaja" />
        <input type="hidden" name="imie" value="Michał" />
        <input type="hidden" name="nazwisko" value="Mojek" />
        <input type="hidden" name="email" value="m.mojek@gmail.com" />
        <input type="hidden" name="postcode" value="00-175" />
        <input type="hidden" name="leadId" value="2" />
        <input
          type="hidden"
          name="URL"
          value="https://swiatecznylist.pl/index.php?leadId=2&amp;t=18"
        />
        <input
          type="hidden"
          name="URLC"
          value="https://swiatecznylist.pl/proksyk/form_active/index.php?r=site/payment&amp;leadId=2"
        />
        <input type="hidden" name="type" value="4" />
        <input type="hidden" name="ignore_last_payment_channel" value="1" />
        <input type="hidden" name="api_version" value="dev" /> */}
      </form>
      <div id="hook_form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-12 text-center">
              <br/>
              <input type="submit" className="big_red" value="Wpłać"/>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Przelewy24Form;