import React, { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";

import LivePreviewContent from "./LivePreviewContent";

const getField = (fields, field) => {
  for (var index in fields) {
    if (fields[index].id === parseInt(field.split("_")[1])) {
      return fields[index];
    }
    if (fields[index].id === parseInt(field.split("__open")[0])) {
      return fields[index];
    }
  }
};

const prepareData = (formWatch, letter_type) => {
  const data = {};
  for (var fw in formWatch) {
    const formValue = formWatch[fw];
    const field = getField(letter_type.letter_fields, fw);
    if (field) {
      data[field.input_tag_name] = formValue;
    }
  }

  for (let vi_field of letter_type.letter_fields.filter(
    (f) => f.field_type === "vi"
  )) {
    // console.log({ vi_field });
    const vi_options = vi_field.letter_field_options;

    for (let vi_o of vi_options) {
      if (vi_field.conditional_letter_field) {
        let vi_label = "";
        if (vi_o.conditional_letter_field_option) {
          const watchConditional =
            formWatch["field_" + vi_field.conditional_letter_field.id];
          if (
            watchConditional &&
            watchConditional.value === vi_o.conditional_letter_field_option.id
          ) {
            vi_label = vi_o.label;
            data[vi_field.input_tag_name] = vi_label;
            break;
          }
        }
        data[vi_field.input_tag_name] = vi_label;
      }
    }
  }
  return data;
};
const LivePreview = ({ letter_type, control }) => {
  const formWatch = useWatch({ control });
  const [data, setData] = useState({});
  const [renderCounter, setRenderCounter] = useState(0);

  // console.log(Object.values(formWatch));

  //   useEffect(() => {
  //     const data = prepareData(formWatch, letter_type);
  //     setPreview(generatePreview(letter_type.template, data));
  //   }, [{ ...formWatch }, letter_type]);
  useEffect(() => {
    setData(prepareData(formWatch, letter_type));
    setRenderCounter(renderCounter + 1);
    // console.log("prepare when form was changed", renderCounter);
  }, [formWatch]);
  return <LivePreviewContent data={data} template={letter_type.template} />;
};

export default LivePreview;
