import React from "react";
import parse from "html-react-parser";

const LivePreviewContent = ({ template, data }) => {
  const generatePreview = (template, data) => {
    for (var f in data) {
      let replacer = "_____________";
      if (typeof data[f] === "string") {
        if (data[f]) replacer = data[f];
      } else {
        // szuka wewnątrz opcji czy nie ma do zamiany hasha z innego pola.
        if (data[f] && data[f].display && data[f].hasInsideReplacer) {
          const hastagregex = /#\S*/gm;
          const foundInsideReplacer = data[f].display.match(hastagregex);

          //wartość znalezionego pola do zamiany
          const replacerField =
            foundInsideReplacer &&
            foundInsideReplacer[0] &&
            data[foundInsideReplacer[0].substring(1)]; // without pre hash #

          replacer = data[f].display.replace(
            foundInsideReplacer[0],
            replacerField ? replacerField : "_____________"
          );
        } else if (data[f] && data[f].display) {
          replacer = data[f].display;
        } else {
          if (data[f] && data[f].label) {
            if (!data[f].open) replacer = data[f].label;
          }
        }
      }
      // console.log({ replacer });

      let stringToReplace = new RegExp("#" + f + "", "g");

      template = template.replace(/\n/g, "<br />");
      template = template.replace(stringToReplace, `<strong>${replacer}</strong>`);
    }
    // to jest dla pól typu select, które pojawia się dopiero po wybraniu innego pola
    template = template.replace(/#\S*/gm, `<strong>_____________</strong>`);
    // return parse(template);
    // console.log(parse(template));
    return parse(template);
  };

  return <div>{generatePreview(template, data)}</div>;
};

export default LivePreviewContent;
