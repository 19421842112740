import React, {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import {useSelector, useDispatch} from "react-redux";
import Select from "react-select";
import classNames from "classnames";

import {
  selectEventConfig,
  selectEventStatus,
} from "../../event_fetcher/EventFetcherSlice";

import {setupLetter, selectLetterContent} from "../letterOrderSlice";

import BackgroundSlider from "../../background_slider/BackgroundSlider";
import LetterFormFields from "./LetterFormFields";

import dziubek from "../../../assets/images/dziubek.png";
import LivePreview from "./LivePreview";
import ReactGA from "react-ga";

const LetterGenerator = () => {
  const {letter_types} = useSelector(selectEventConfig);
  const eventStatus = useSelector(selectEventStatus);
  const letterContent = useSelector(selectLetterContent);

  const {
    control,
    register,
    watch,
    handleSubmit,
    setValue,
    unregister,
    reset,
    getValues,

    formState: {errors, touchedFields},
  } = useForm({defaultValues: letterContent});
  // console.log({ letterContent });
  // console.log({ touchedFields });
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    // console.log(data);
    // reset();
    dispatch(setupLetter(data));
    ReactGA.event({
      category: 'Wyślij list',
      action: "Wybierz grafikę i treść listu",
      label: 'Zobacz podgląd',
    });
  };

  const letterType = watch("letter_type");
  const background = watch("background_id");

  const selectedLetterType = letterType
    ? letter_types.find((element) => element.id === letterType.value)
    : null;

  useEffect(() => {
    if(!letterType){
      return;
    }
    if(!selectedLetterType){
      return;
    }
    console.log("selectedLetterType", selectedLetterType);
     letter_types.map((letterType, index) => {
      if(letterType.id === selectedLetterType.id) {
        ReactGA.event({
          category: 'Wyślij list',
          action: "Wybierz grafikę i treść listu",
          label: 'Wypełnij treść listu',
          value: index + 1,
        });
      }
    })

  }, [letterType])

  useEffect(() => {
    reset({...getValues()});
    // this unregister inputs except letter_type
    for (var f in getValues()) {
      if (f !== "letter_type") {
        unregister(f, {keepValue: true});
      }
    }

    // dispatch(setupLetter({ background_id: background }));
  }, [letterType, reset, unregister, getValues]);

  if (eventStatus !== "ready") {
    return <div>loading</div>;
  }
  const options = [];

  letter_types.forEach((letter_type) => {
    options.push({value: letter_type.id, label: letter_type.name});
  });

  // console.log({ errors });
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid  letter-container chooser">
          <div className="container text-center">
            <h3 className={classNames({
              error: errors.background_id,
            })}>
              Wybierz grafikę </h3>
            <BackgroundSlider isOnForm={true} register={register} setValue={setValue} background={background}/>
          </div>
        </div>
        {background && (
          <div className="container-fluid formgen">
            <div className="container">
              <h3 className="text-center">Wypełnij treść listu</h3>
              <label>Dla kogo? </label>

              <Controller name="letter_type" control={control} render={({field}) => (
                <Select {...field} options={options} placeholder="" className="select-letter" />
              )}/>
              {selectedLetterType && (
                <>
                  <div className="small_preview">
                    <img src={dziubek} id="dziubek" alt="rodzaj listu"/>
                    <div className="preview">
                      <LivePreview letter_type={selectedLetterType} control={control}/>
                    </div>
                  </div>
                  <LetterFormFields letterType={selectedLetterType} control={control} register={register}
                                    errors={errors} setValue={setValue} touchedFields={touchedFields}
                                    unregister={unregister}/>
                  <div className="row">
                    <div className="col-md-12 text-center preview_infor">
                      Wypełnij uważnie wszystkie pola formularza. <br/> Sprawdź
                      ponownie, czy Mikołaj nigdzie się nie pomylił <br/>i czy
                      wszystkie pola są poprawnie wypełnione.
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <button className="big_red">Zobacz podgląd</button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default LetterGenerator;
