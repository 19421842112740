import React from "react";
import one from "./../../assets/images/1step_color.png";
import onegrey from "./../../assets/images/1step_color.png";
import onesmall from "./../../assets/images/1only.png";
import onesmallgrey from "./../../assets/images/1only.png";

import two from "./../../assets/images/2step_color.png";
import twogrey from "./../../assets/images/2step_grey.png";
import twosmall from "./../../assets/images/2only.png";
import twosmallgrey from "./../../assets/images/2onlybw.png";

import three from "./../../assets/images/3step_color.png";
import threegrey from "./../../assets/images/3step_grey.png";
import threesmall from "./../../assets/images/3only.png";
import threesmallgrey from "./../../assets/images/3onlybw.png";

const HeaderIcons = ({ step }) => {
  const icons = [one, two, three];
  const iconsgrey = [onegrey, twogrey, threegrey];
  const iconssmall = [onesmall, twosmall, threesmall];
  const iconssmallgrey = [onesmallgrey, twosmallgrey, threesmallgrey];
  const getIcon = (iconstep) => {
    return iconstep <= step ? icons[iconstep] : iconsgrey[iconstep];
  };
  const getIconSmall = (iconstep) => {
    return iconstep <= step ? iconssmall[iconstep] : iconssmallgrey[iconstep];
  };
  return (
    <div className="container-fluid steps-container ng-scope">
      <div className="container text-center">
        <h2>Wyślij list</h2>
        <div className="tall">
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="step step1 color">
                <div className="row">
                  <div className="col-md-5 col-sm-6 col-xs-2">
                    <img src={getIcon(0)} className="hidden-xs" alt="step 1" />
                    <img
                      src={getIconSmall(0)}
                      className="hidden-sm hidden-md hidden-lg"
                      alt="step 1"
                    />
                  </div>
                  <div className="col-md-7 col-sm-6 col-xs-10">
                    <div className="text-left">
                      Wybierz grafikę
                      <br /> i treść listu
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="step step2 grey">
                <div className="row">
                  <div className="col-md-5 col-sm-6  col-xs-2">
                    <img src={getIcon(1)} className="hidden-xs" alt="step 2" />
                    <img
                      src={getIconSmall(1)}
                      className="hidden-sm hidden-md hidden-lg"
                      alt="step 2"
                    />
                  </div>
                  <div className="col-md-7 col-sm-6 col-xs-10">
                    <div className="text-left">
                      Wypełnij formularz
                      <br className="hidden-sm" /> adresowy{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="step step3 grey">
                <div className="row">
                  <div className="col-md-5 col-sm-6  col-xs-2">
                    <img src={getIcon(2)} className="hidden-xs" alt="step 3" />
                    <img
                      src={getIconSmall(2)}
                      className="hidden-sm hidden-md hidden-lg"
                      alt="step 3"
                    />
                  </div>
                  <div className="col-md-7 col-sm-6  col-xs-10">
                    <div className="text-left">
                      Przekaż darowiznę <br />i czekaj na list
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderIcons;
