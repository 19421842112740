import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postFindPostalCode } from "./postalCodeApi";
const initialState = {
  status: "idle",
  target: null,
  senderPostalCode: [],
  receiverPostalCode: [],
};

export const findPostalCodeAsync = createAsyncThunk(
  "postalCode/search",
  async (data) => {
    const response = await postFindPostalCode(data);
    // The value we return becomes the `fulfilled` action payload

    return response.data;
  }
);

export const postalCodeSlice = createSlice({
  name: "postalCode",
  initialState,
  reducers: {
    setupTarget: (state, action) => {
      console.log({ action });
      state.target = action.payload;
    },
    clearPostalCodes: (state) => {
      state.senderPostalCode = [];
      state.receiverPostalCode = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(findPostalCodeAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(findPostalCodeAsync.fulfilled, (state, action) => {
        state.status = "ready";
        console.log({ state });
        if (state.target === "sender") {
          state.senderPostalCode = action.payload.postal_codes;
        }
        if (state.target === "receiver") {
          state.receiverPostalCode = action.payload.postal_codes;
        }
      });
  },
});
export const selectSenderPostalCode = (state) =>
  state.postalCode.senderPostalCode;
export const selectReceiverPostalCode = (state) =>
  state.postalCode.receiverPostalCode;
export const selectStatus = (state) => state.postalCode.status;
export const { setupTarget, clearPostalCodes } = postalCodeSlice.actions;
export default postalCodeSlice.reducer;
