import React from "react";
import { useSelector } from "react-redux";
import { selectEventConfig } from "../../features/event_fetcher/EventFetcherSlice";
import { letter_variety } from "../../tools/letter_variety";

const LetterCounter = () => {
  const { letters_counter } = useSelector(selectEventConfig);
  if (!letters_counter) {
    return "";
  }
  return (
      <div
          className="container-fluid counter-container ng-scope"
          ng-if="mikolaj.step==1"
    >
      <div className="container">
        <div className="full-counter-container">
          <div className="counter-header">Napisaliście już</div>
          <div className="counter-counter">
            <span>{letters_counter}</span> {letter_variety(letters_counter)}
          </div>
        </div>
      </div>
    </div>
  );
};
// var a = [1, 2, 3];
// a.includes(2); // true
// a.includes(4); // false


export default LetterCounter;
