import React from "react";
import biglogo from "../assets/images/fdds_big.png";
function Footer() {
  return (
    <div className="container-fluid footer-container">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <strong>Kontakt</strong> <br />
            Fundacja Dajemy Dzieciom Siłę <br />
            ul. Mazowiecka 12/25 <br />
            00-048 Warszawa <br />
            e-mail: <a href="mailto:mikolaj@fdds.pl">mikolaj@fdds.pl</a>
          </div>
          <div className="col-md-6 col-sm-6 text-right">
            <a href="http://fdds.pl" target="_blank" rel="noreferrer">
              <img src={biglogo} width="150" alt="Fundacja DDS" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
