import React from 'react';
import {selectEventConfig} from '../../features/event_fetcher/EventFetcherSlice'
import {useSelector} from "react-redux";
import {nl2br} from "../../tools/nl2br";


const HowHelp = () => {
  const {how_help} = useSelector(selectEventConfig);
  return (
    <div>
      <div className = "container-fluid subpage nh">

        <div className = "container subpage nh">
          <div className = "tall" style = {{width: "100%"}}>
            <div className = "row">
              <div className = "col-md-12 toper">
                <h2 className = "text-center">{how_help.main_header}</h2>
                <p className = "text-center">
                  {nl2br(how_help.main_content)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className = "container-fluid subpage nh greyblack_light">
        <div className = "container subpage nh">
          <div className = "tall" style = {{width: "100%"}}>
            <div className = "row">
              <div className = "col-md-7 outerspace">
                <img src = {how_help.image} alt = {"Jak pomagamy"} className = "img-responsive" />
              </div>
              <div className = "col-md-5">
                <ul className = "morepad-sm noarrow">
                  {how_help.elements.map((element) => (
                    <li dangerouslySetInnerHTML = {{
                      __html: element.parsed_element
                    }}
                    />
                  ))}
                  <li />
                </ul>
              </div>

            </div>
          </div>
        </div>


      </div>


    </div>
  );
};

export default HowHelp;
