import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectEventConfig} from "../../event_fetcher/EventFetcherSlice";
import {useParams} from "react-router-dom";
import {
    changeStep,
    saveLetterAsync,
    selectBackgroundIsSpecial,
    selectContactForm,
    selectCoupon,
    selectLetterContent,
    selectLetterId,
    selectLimitAchieved,
    selectPartner,
} from "../letterOrderSlice";
import PaymentChooser from "./PaymentChooser";
import PaymentInfo from "./PaymentInfo";

const Payment = () => {
    const dispatch = useDispatch();
    const letterContent = useSelector(selectLetterContent);
    const contactForm = useSelector(selectContactForm);
    const coupon = useSelector(selectCoupon);
    const partner = useSelector(selectPartner);
    const letterId = useSelector(selectLetterId);
    const limitAchieved = useSelector(selectLimitAchieved);
    const backgroundIsSpecial = useSelector(selectBackgroundIsSpecial);
    const {event} = useSelector(selectEventConfig);
    let params = useParams();
    useEffect(() => {
        !params.letterId &&
        dispatch(
            saveLetterAsync({
                fields: {...letterContent},
                ...contactForm,
                coupon: coupon,
                partner: partner,
                event_id: event && event.id,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    let paymentForm;

    if (letterId) {
        paymentForm = (
            <PaymentChooser sender={contactForm.sender} letterId={letterId} backgroundIsSpecial={backgroundIsSpecial}/>
        );
    } else if (limitAchieved) {
        paymentForm = (
            <div className="container text-center">
                <h3> Mikołaj nie ma już więcej listów z limitowanej edycji! <br/>Nie martw się, treść i dane Twojego listu
                    zostały zapisane.<br /> Wybierz jedną z pozostałych grafik i dokończ zamawianie listu.</h3>
                <p style={{marginTop: '40px', marginBottom: "100px"}}>
                    <button onClick={() => dispatch(changeStep(0))}
                            className="big_red"

                    >
                        Wybierz inną grafikę
                    </button>
                </p>
            </div>
        )
    } else {
        paymentForm = (
            <div className="container text-center">
                <h3>Zapisywanie listu...</h3>

            </div>
        );
    }
    if (coupon && coupon.free) {
        paymentForm = "";
    }
    if (partner && partner.free) {
        paymentForm = "";
    }
    return (
        <div>
            {!limitAchieved && <PaymentInfo coupon={coupon} partner={partner}/>}
            {paymentForm}
        </div>
    );
};

export default Payment;
