import React from "react";
import { InfoBox } from "./CheckThermsStyled";
import {get} from "../../../tools/get";
import classNames from "classnames";
const CheckTherms = ({register, errors}) => {
  return (
    <div className="row">
      <div className="col-md-12">
          <InfoBox>

              <label
                  className={classNames({
                      error: get(errors, "terms"),
                  })}
              >
                  <input
                      style={{marginRight: "10px"}}
                      type="checkbox"
                      {...register("terms", {
                          required: true,
                      })}
                  />
                  Oświadczam, że zapoznałem/łam się z klauzulą informacyjną i wyrażam zgodę na przetwarzanie moich
                  danych osobowych przez Fundację Dajemy Dzieciom Siłę
                  <a
                      href="https://fdds.pl/polityka-prywatnosci-online-fdds.html"
                      target="_blank"
                      rel="noreferrer"
                  > WIĘCEJ</a>
              </label>
          </InfoBox>
      </div>
    </div>
  );
};

export default CheckTherms;
