import React from "react";

import one from "../../assets/images/1.png";
import two from "../../assets/images/2.png";
import three from "../../assets/images/3.png";

import onemobilecolor from "../../assets/images/1step_color.png";
import twomobilecolor from "../../assets/images/2step_color.png";
import threemobilecolor from "../../assets/images/3step_color.png";

function WritingProcess() {
  return (
    <div className="container-fluid process-container">
      <div className="container text-center hidden-xs">
        <h2>Zobacz, jakie to proste!</h2>
        <div className="row">
          <div className="col-md-4 col-sm-4 ">
            <img src={one} alt="krok pierwszy" />
          </div>
          <div className="col-md-4  col-sm-4 text-center">
            <img src={two} alt="krok drugi" />
          </div>
          <div className="col-md-4  col-xs-4 text-center">
            <img src={three} alt="krok trzeci" />
          </div>
        </div>
        <div className="row info_text">
          <div className="col-md-4  col-sm-4">
            Wybierz grafikę <br />i treść listu
          </div>
          <div className="col-md-4  col-sm-4 text-center">
            Wypełnij formularz <br />
            adresowy
          </div>
          <div className="col-md-4  col-sm-4 text-center">
            Przekaż darowiznę <br />i czekaj na list
          </div>
        </div>
      </div>

      <div className="container text-rleftt hidden-sm hidden-md hidden-lg">
        <div className="tall">
          <h2>Zobacz, jakie to proste!</h2>
          <div className="row">
            <div className="col-xs-6">
              <img src={onemobilecolor} alt="krok pierwszy" />
            </div>
            <div className="col-xs-6">
              Wybierz grafikę <br />i treść listu
            </div>
          </div>

          <div className="row">
            <div className="col-xs-6">
              <img src={twomobilecolor} alt="krok drugi" />
            </div>
            <div className="col-xs-6">Wypełnij formularz adresowy</div>
          </div>

          <div className="row">
            <div className="col-xs-6">
              <img src={threemobilecolor} alt="krok trzeci" />
            </div>
            <div className="col-xs-6">
              Przekaż darowiznę <br />i czekaj na list
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WritingProcess;
