export const letter_variety = (n) => {
  const last_number = n.toString().slice(-1);
  if (n === 1) {
    return "list";
  }
  if ([2, 3, 4].includes(n)) {
    return "listy";
  }
  if ([5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19].includes(n)) {
    return "listów";
  }
  if (last_number === "0") {
    return "listów";
  }
  if (last_number === "1") {
    return "listów";
  }
  if (last_number === "2") {
    return "listy";
  }
  if (last_number === "3") {
    return "listy";
  }
  if (last_number === "4") {
    return "listy";
  }
  return "listów";
};
