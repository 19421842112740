import React, { useEffect } from "react";
import infoIcon from "../../../assets/images/info.png";
import $ from "jquery";
import classNames from "classnames";
import { get } from "../../../tools/get";

const PhoneField = ({ register, errors, label, name }) => {

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
  }, []);

  return (
      <div className="form-group">
        <label
        className={classNames("text-muted", {
          error: get(errors, name),
        })}
      >
        {label}{" "}
        <img
          src={infoIcon}
          data-toggle="tooltip"
          data-placement="top"
          title=""
          data-original-title="Wpisz numer telefonu, na który chcesz otrzymać sms z potwierdzeniem wysłania listu"
          alt="Pomoc"
        />
      </label>
      <input
          type="text"
          {...register(name, {
          required: false,
          pattern: {
            value: /^\d{9}$/i,
            message: "Wpisz telefon w formacie 000000000",
          },
        })}
          placeholder="000000000"
          title="Wpisz numer telefonu, na który chcesz otrzymać sms z potwierdzeniem wysłania listu"
          className="form-control"
      />

        {errors[name] && <p className="error">{errors[name].message}</p>}
    </div>
  );
};

export default PhoneField;
