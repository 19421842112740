import styled, { keyframes } from "styled-components";

const breatheAnimation = keyframes`
    0% { opacity: 1; visibility: visible; }
   100% { opacity: 0; visibility: hidden;  }
  `;
export const CouponError = styled.div`
  max-width: 1366px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: red;
  width: 100vw;
  padding: 3px;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  animation-name: ${breatheAnimation};
  animation-delay: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-iteration-count: 1;
`;
