import React from 'react';
import {selectEventConfig} from '../../features/event_fetcher/EventFetcherSlice'
import {useSelector} from "react-redux";
import TopContent from "./TopContent";


const AboutPage = () => {
  const {about_page} = useSelector(selectEventConfig);

  return (
      <>
        <TopContent aboutPage={about_page}/>
        <div className="container-fluid  ofundacji border-top">
          <div className="container subpage nh">
            <div className="row border-top">
              <h3 className="text-center">{about_page.sub_header}</h3>
              <div className="col-md-7 hidden-md hidden-lg outerspace">
                <img src={about_page.image} alt="O fundacji" className="img-responsive"/>
              </div>
              <div className="col-md-5">
                <ul>
                  {about_page.elements.map((element, index) => (
                      <li> {element.element}</li>
                  ))}
                  <li dangerouslySetInnerHTML={{
                    __html: about_page.more_info
                  }}
                  />
                </ul>
              </div>
              <div className="col-md-7 hidden-xs hidden-sm">
                <img src={about_page.image} className="img-responsive" alt="o Fundacji"/>
              </div>
            </div>

          </div>
        </div>
      </>
  );
};

export default AboutPage;
