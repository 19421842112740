import {nl2br} from "../../tools/nl2br";
import React from "react";

const TopContent = (props) => {
  return <div className="container-fluid subpage ofundacji nh">

    <div className="container subpage nh">
      <div className="tall" style={{width: "100%"}}>
        <div className="row">
          <div className="col-md-12 toper">
            <h2 className="text-center">{props.aboutPage.main_header}</h2>
            <p className="text-center">
              {nl2br(props.aboutPage.main_content)}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>;
}
export default TopContent;
