import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import LivePreview from "./../letter_generator/LivePreview";
import {PreviewContainer, PreviewPositioner} from "./PreviewStyled";
import {
  selectLetterContent,
  changeStep,
  selectStep,
} from "../letterOrderSlice";
import {
  selectEventConfig,
  selectEventStatus,
} from "../../event_fetcher/EventFetcherSlice";
import ReactGA from "react-ga";

const Preview = () => {
  const dispatch = useDispatch();
  const letterContent = useSelector(selectLetterContent);
  const {letter_types, backgrounds} = useSelector(selectEventConfig);
  const step = useSelector(selectStep);
  const {watch, control} = useForm({defaultValues: letterContent});
  const letterType = watch("letter_type");
  const background_id = watch("background_id");
  // console.log(background_id, backgrounds);
  const selectedBackground = backgrounds.find((f) => f.id === background_id);

  console.log(selectedBackground.preview_url);
  const selectedLetterType = letterType
    ? letter_types.find((element) => element.id === letterType.value)
    : null;


  const handleBack = () => {
    dispatch(changeStep(step - 1));
    ReactGA.event({
      category: 'Wyślij list',
      action: "Wybierz grafikę i treść listu",
      label: 'Popraw',
    });
  }

  const handleNext = () => {
    dispatch(changeStep(step + 1));
    ReactGA.event({
      category: 'Wyślij list',
      action: "Wybierz grafikę i treść listu",
      label: 'Zaakceptuj',
    });
  }
  return (
    <div className="container">
      <PreviewContainer background={selectedBackground}>
        <PreviewPositioner background={selectedBackground} content_font_size={selectedLetterType.content_font_size}>
          <LivePreview letter_type={selectedLetterType} control={control}/>
        </PreviewPositioner>
      </PreviewContainer>
      <div className="row mt-4 mb-4">
        <div className="col-xs-6 text-center">
          <button onClick={() => handleBack()} className="big_red">
            Popraw
          </button>
        </div>
        <div className="col-xs-6 text-center">
          <button onClick={() =>handleNext()} className="big_red">
            Zaakceptuj
          </button>
        </div>
      </div>
    </div>
  );
};

export default Preview;
