import React from "react";
import classNames from "classnames";
import { get } from "../../../tools/get";

const InputLabel = ({
                      label,
                      placeholder,
                      name,
                      register,
                      pattern,
                      errors,
                      autoComplete,
                      children,
                      required,
                    }) => {
  return (
    <div className="form-group">
      <label
        className={classNames({
          error: get(errors, name),
        })}
      >
        {label}
      </label>
      <input
        type="text"
        className="form-control"
        placeholder={placeholder}
        autoComplete={autoComplete ? autoComplete : ""}
        {...register(name, {
          required: required,
          pattern: pattern ? pattern : null,
        })}
      />
      {children}
    </div>
  );
};

export default InputLabel;
