import React from "react";
import classNames from "classnames";
import { get } from "../../../tools/get";

const EmailField = ({ name, label, register, errors, autoComplete }) => {
  return (
      <div className="form-group">
        <label
            className={classNames({
              error: get(errors, name),
            })}
        >
          {label}
      </label>
      <input
        type="email"
        className="form-control"
        autoComplete={autoComplete ? autoComplete : ""}
        {...register(name, {
          required: true,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: "Wpisz poprawny adres e-mail",
          },
        })}
      />
      {errors[name] && <p className="error">{errors[name].message}</p>}
    </div>
  );
};

export default EmailField;
