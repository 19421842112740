import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchEventConfig } from "./EventFetcherApi";

const initialState = {
  event: {},
  status: "idle",
};

export const eventFetcherAsync = createAsyncThunk(
    "eventFetcher/fetchEventConfig",
    async (amount) => {
      const response = await fetchEventConfig();
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    }
);

export const eventFetcherSlice = createSlice({
  name: "eventFetcher",
  initialState,
  reducers: {
    backdoorActivateEvent: (state) => {
      state.event.event.active = true;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(eventFetcherAsync.pending, (state) => {
          state.status = "loading";
        })
        .addCase(eventFetcherAsync.fulfilled, (state, action) => {
          state.status = "ready";
           const filteredData = action.payload.backgrounds.filter(
               (background) =>
                   !background.special_letter ||
                   (background.special_letter && background.special_letter_limit > background.letter_count)
           );
          state.event = { ...action.payload, backgrounds: filteredData }
          ;
        });
  },
});
export const selectEventConfig = (state) => state.eventFetcher.event;
export const selectEventStatus = (state) => state.eventFetcher.status;
export const { backdoorActivateEvent } = eventFetcherSlice.actions;
export default eventFetcherSlice.reducer;
