import React, {useState} from 'react';
import {selectEventConfig} from '../../features/event_fetcher/EventFetcherSlice'
import {useSelector} from "react-redux";

const Faq = () => {
    const {faq} = useSelector(selectEventConfig);
    const [currenIndex, setIndex] = useState(-1);
    return (
        <div className="container-fluid subpage nh">

            <div className="container">
                <div className="row">
                    <div className="col-md-offset-2 col-md-8 faq">
                        <h2 className="text-center">{faq.main_header}</h2>
                        {faq.elements.map((element, index) => (
                            <p>
                                <strong onClick={() => setIndex(index)}>{index + 1} {element.header}</strong>
                                {currenIndex === index &&
                                <span dangerouslySetInnerHTML={{
                                    __html: element.element
                                }}
                                />
                                }
                            </p>
                        ))}


                    </div>
                </div>
            </div>
        </div>
    );
};

export default Faq;
