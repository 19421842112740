import React from 'react';
import {selectEventConfig} from '../../features/event_fetcher/EventFetcherSlice'
import {useSelector} from "react-redux";
import {nl2br} from "../../tools/nl2br";

const HowToWrite = () => {
  const {how_to_write} = useSelector(selectEventConfig);
  return (
    <>
      <div className = "container subpage nh" style = {{fontSize: '140%'}}>
        <div className = "tall center-sm center-xs tall100 ">
          <div className = "row">
            <div className = "col-md-12 toper">
              <h2 className = "text-center">{how_to_write.main_header}</h2>
              <p className = "text-center">
                {nl2br(how_to_write.main_content)}
              </p>
              <p className = "text-center" style = {{position: 'relative', top: '20px;'}}>
                <strong>
                  {nl2br(how_to_write.sub_header)}
                </strong>
              </p>
            </div>


          </div>

          {how_to_write.elements.map((element) => (
            <div className = "row">
              <div className = "col-md-3 col-sm-3">
                <img src = {element.image} className = "hidden-xs" alt = {"wybierz"} />

                <img src = {element.image} className = "hidden-sm hidden-md hidden-lg" style = {{width: '30%'}}
                     alt = {"wybierz"}
                />

              </div>

              <div className = "col-md-9 col-sm-9  light alignmidle" dangerouslySetInnerHTML = {{
                __html: element.element
              }}
              />
              <br className = {"hidden-lg"} />
            </div>


          ))}
        </div>
      </div>
      <div className = "container-fluid greyblack subpage nh">
        <div className = "container subpage nh">
          <div className="text-center light litlepad3x">
              <div className="litlepad" dangerouslySetInnerHTML={{
                  __html: how_to_write.more_info
              }}
              />

          </div>
        </div>

      </div>


      <div className = "container-fluid blueblack subpage nh">
        <div className = "container subpage nh">
          <div className = "text-center light ">
            <h2 className = "red shadow text-center">
              UWAGA!<br />
              Mikołaj wysyła listy…
            </h2>
            <div className = "row litlepad2x">
              <div className = {'col-md-2'} />
              {how_to_write.dates.map((element) => (
                <div className = "col-md-4">
                    <img src={element.image} alt={'im'} width={"150px"}/>
                    <div className="litlepad" dangerouslySetInnerHTML={{
                        __html: element.element
                    }}
                    />

                </div>
              ))}
            </div>

          </div>
        </div>

      </div>


    </>
  );
};

export default HowToWrite;
