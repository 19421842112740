import React, { useEffect } from "react";
import ReactGA from "react-ga";
import HeaderIcons from "./HeaderIcons";
import { useSelector } from "react-redux";
import { selectStep } from "./../../features/letter_order/letterOrderSlice";

import { LetterOrderFeature } from "../../features/letter_order/LetterOrderFeature";

const LetterOrder = () => {


  const step = useSelector(selectStep);
  useEffect(() => {
    const step_arr = ['generator', 'podglad_listu', 'dane_kontaktowe', 'darowizna']
    ReactGA.pageview(window.location.pathname + '/' + step_arr[step]);
  }, [step]);
  let icon_step = step;
  if (step > 0) {
    icon_step = step - 1;
  }
  return (
      <>
        <HeaderIcons step={icon_step}/>
        <LetterOrderFeature/>
      </>
  );
};

export default LetterOrder;
