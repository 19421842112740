import React, { useEffect } from "react";
import WritingProcess from "./WritingProcess";
import LettersContainer from "./LettersContainer";
import { selectEventConfig } from "../../features/event_fetcher/EventFetcherSlice";

import {
  checkPartnerCodeAsync,
  getCouponSalesForceIdAsync,
  selectCouponState
} from "../../features/letter_order/letterOrderSlice";
import LetterCounter from "./LetterCounter";
import ShortInfo from "./ShortInfo";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CouponError } from "./MainPageStyled";

const MainPage = () => {
  let params = useParams();

  const dispatch = useDispatch();
  const { event } = useSelector(selectEventConfig);
  const couponState = useSelector(selectCouponState);
  useEffect(() => {
    if (params.coupon) {
      dispatch(
          getCouponSalesForceIdAsync({ coupon: params.coupon, event: event.id })
      );
    }
  }, []);

  useEffect(() => {
    if (params.partner) {
      dispatch(
          checkPartnerCodeAsync({ partner: params.partner, event: event.id })
      );
    }
  }, []);

  return (
      <>
        {couponState && couponState === "error" && (
            <CouponError>Niepoprawny kod kuponu</CouponError>
        )} {couponState && couponState === "invalid" && (
          <CouponError>Twój kupon został już wykorzystany</CouponError>
      )}

        <WritingProcess/> <LettersContainer/> <LetterCounter/> <ShortInfo/>
      </>
  );
};

export default MainPage;
