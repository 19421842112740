import styled from "styled-components";

export const PostalCodeBox = styled.div`
  background: #fff;
  display: inline-block;
  padding: 0px 5px;
  border-radius: 3px;
  margin-right: 4px;
  cursor: pointer;
`;
export const SearchLink = styled.div`
  color: #337ab7;
  margin-bottom: 0px;
  margin-top: 2px;
`;
