import React from "react";
import { Link } from "react-router-dom";
import sendButtonBig from "../assets/images/wyslij_list.png";
import { letter_variety } from "../tools/letter_variety";
import { selectEventConfig } from "../features/event_fetcher/EventFetcherSlice";
import { useSelector } from "react-redux";

function MainTeaser() {
  const { letters_counter } = useSelector(selectEventConfig);
  return (
      <>
        <div className="row">
          <div className="col-md-6">
            <div className="teaser text-center">
             Spraw bliskim radość!
              <p>
                <Link to="/zamow-list">
                  <img src={sendButtonBig} alt="Wyślij list"/>
                </Link>
              </p>
              <p>
                ...bo magia świąt zaczyna się <br/> od Listu od Mikołaja!
              </p>

            </div>

          </div>

        </div>
        <div className="teaser-counter">
          <div className="counter-header">Napisaliście już</div>
          <div className="counter-counter">
            <span>{letters_counter}</span> {letter_variety(letters_counter)}
          </div>
        </div>
      </>
  );
}

export default MainTeaser;
