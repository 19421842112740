import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import letterOrderReducer from "../features/letter_order/letterOrderSlice";
import eventFetcherReducer from "../features/event_fetcher/EventFetcherSlice";
import postalCodeReducer from "../features/letter_order/contact_form/postal_code/postalCodeSlice";
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    letterorder: letterOrderReducer,
    eventFetcher: eventFetcherReducer,
    postalCode: postalCodeReducer,
  },
});
