// A mock function to mimic making an async request for data
import axios from "axios";
export function postLetterSave(letter_data) {
  return axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/letter/`, {
    letter_data,
  });
}

export function getLetterInfo(letter_id, email) {
  return axios.get(
    `${process.env.REACT_APP_API_DOMAIN}/api/letter/${letter_id}/${email}`,
    {}
  );
}

export function getCouponSalesForceId(coupon, event) {
  return axios.get(
    `${process.env.REACT_APP_API_DOMAIN}/api/coupon/${coupon}/${event}`,
    {}
  );
}

export function checkPartnerCode(coupon, event) {
  return axios.get(
    `${process.env.REACT_APP_API_DOMAIN}/api/partner/${coupon}/${event}`,
    {}
  );
}
