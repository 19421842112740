import React from "react";
import LetterField from "./LetterField";
const LetterFormFields = ({
  letterType,
  control,
  register,
  errors,
  setValue,
  touchedFields,
  unregister,
}) => {
  const letterFields = letterType.letter_fields;
  return (
    <div className="row">
      <div className="col-md-6 col-sm-6">
        {letterFields
          .filter((f) => f.column === 1)
          .map((letterField, index) => (
            <LetterField
              control={control}
              register={register}
              letterField={letterField}
              key={letterField.id}
              errors={errors}
              setValue={setValue}
              touchedFields={touchedFields}
              unregister={unregister}
            />
          ))}
      </div>
      <div className="col-md-6 col-sm-6">
        {letterFields
          .filter((f) => f.column === 2)
          .map((letterField, index) => (
            <LetterField
              control={control}
              register={register}
              letterField={letterField}
              key={letterField.id}
              errors={errors}
              setValue={setValue}
              touchedFields={touchedFields}
              unregister={unregister}
            />
          ))}
      </div>
    </div>
  );
};

export default LetterFormFields;
// todo
// - add custom field for open option
// - conditional fields
// save to redux store
