import React, { useEffect } from "react";

import { eventFetcherAsync } from "./EventFetcherSlice";
import { useDispatch } from "react-redux";

export const EventFetcher = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(eventFetcherAsync());
  }, [dispatch]);

  return <></>;
};
