import React from "react";
import BackgroundSlider from "../../features/background_slider/BackgroundSlider";

const LettersContainer = () => {
    return (
        <>
            <div className="container letter-container">
                <div className="container text-center">
                    <h2>Stwórz wyjątkowy list!</h2>
                    <p>
                        Wybierz jedną z grafik
                        i&nbsp;dowolnie dopasuj do niej treść.<br/>
                        &nbsp;Możesz też wybrać list z&nbsp;limitowanej edycji* -&nbsp;koperta takiego listu będzie
                        zapieczętowana
                        świąteczną pieczątką!
                        <br />
                        <small style={{fontSize: "11px"}}>* Jeśli nie widzisz listu z limitowanej edycji to znaczy, że nakład już się wyczerpał. </small>

                    </p>
                    <BackgroundSlider/>
                </div>
            </div>
        </>
    );
};

export default LettersContainer;
