import React from "react";
import classNames from "classnames";
const PaymentAmount = ({
  children,
  amountVal,
  selectedAmount,
  handleClick,
  handleOther,
  minimum_amount,
}) => {
  if (amountVal) {
    return (
      <>
        <div
          onClick={handleClick}
          className={classNames("dot-chooser", {
            check: amountVal.toString() === selectedAmount,
          })}
        >
          {amountVal} zł
        </div>
        <div className="dot-info hidden-xs">{children}</div>
      </>
    );
  } else {
    return (
      <>
        <div
          onClick={handleClick}
          className={classNames(["dot-chooser", "dot-chooser-innakwota"], {
            check: selectedAmount === "other",
          })}
        >
          {/* <div className="inna_label">Inna kwota</div> */}
          <input
            type="number"
            min={minimum_amount}
            step="5"
            className="form-control inna_input"
            placeholder="Wpisz kwotę"
            onChange={handleOther}
          />
        </div>
        <div className="dot-info hidden-xs">{children}</div>
      </>
    );
  }
};

export default PaymentAmount;
