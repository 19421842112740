import styled from "styled-components";
const arrowBreakPoint = 992;

export const PreviewContainer = styled.div`
  background: ${(props) =>
    `url(${props.background.preview_url}) no-repeat top center`};
  min-height: 1263px;
  position: relative;
  margin: 0 auto;
  font-weight: 300;
  width: 900px;

  @media (max-width: ${arrowBreakPoint}px) {
    background: none;
    min-height: auto;
    width: auto;
  }
  br {
    display: block;
    margin: 16px 0;
    content: " ";
    line-height: 16px;
  }
`;

export const PreviewPositioner = styled.div`
  position: absolute;
  @media (min-width: ${arrowBreakPoint + 1}px) {
    top: ${(props) => `${props.background.content_position_top}`};
    left: ${(props) => `${props.background.content_position_left}`};
    width: ${(props) => `${props.background.content_position_width}`};
    font-size: ${(props) => `${props.content_font_size}`};
  }
  @media (max-width: ${arrowBreakPoint}px) {
    position: static;
  }
`;
