import React, { useEffect } from "react";
import MenuTop from "./MenuTop";

import MainTeaser from "./MainTeaser";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";
import { useLocation } from "react-router-dom";
import { backdoorActivateEvent, selectEventConfig } from "../features/event_fetcher/EventFetcherSlice";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const { event } = useSelector(selectEventConfig);

  const location = useLocation();
  const mainPagePath =
      (location.pathname === "/" ||
          location.pathname.includes("backdoor") ||
          location.pathname.includes("kupon") ||
          location.pathname.includes("partner")) &&
      event.active;

  useEffect(() => {
    location.pathname.includes("backdoor") && dispatch(backdoorActivateEvent())
    location.pathname.includes("partner") && dispatch(backdoorActivateEvent())
    location.pathname.includes("kupon") && dispatch(backdoorActivateEvent())
  }, [])
  return (
      <>
        <ScrollToTop/>
        <div
            className={classNames("container-fluid", "header-container", {
              "header-container": mainPagePath,
              "header-small-container": !mainPagePath,
            })}
        >
          <div className="container">
            <MenuTop/>

            {mainPagePath && <MainTeaser/>}
          </div>
        </div>
        {!event.active && location.pathname === "/" && (
            <div className="container" style={{ padding: "50px 20px" }}>
              <div className="row">
                <div className="col-md-6">
                  <img src={event.cap_image} alt="Dziękujemy" width="100%"/>
                </div>
                <div className="col-md-6">
                  <div
                      style={{ marginTop: "78px", fontSize: "130%" }}
                      dangerouslySetInnerHTML={{
                        __html: event.cap,
                      }}
                  />
                </div>
              </div>
            </div>
        )}
        {children}

        <Footer/>
      </>
  );
};

export default Layout;
