import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SearchLink, PostalCodeBox } from "./PostalCodeStyled";

import {
  setupTarget,
  clearPostalCodes,
  selectSenderPostalCode,
  selectReceiverPostalCode,
  selectStatus,
  findPostalCodeAsync,
} from "./postalCodeSlice";
const PostalCode = ({ target, watch, setValue }) => {
  const dispatch = useDispatch();

  const person = watch(target);

  let selectPostalCode;
  if (target === "sender") {
    selectPostalCode = selectSenderPostalCode;
  }
  if (target === "receiver") {
    selectPostalCode = selectReceiverPostalCode;
  }
  const postalCodes = useSelector(selectPostalCode);
  const status = useSelector(selectStatus);
  const payloadData = {
    street_address: person && person.street_address,
    street_number: person && person.street_number,
    city: person && person.city,
  };
  const handleClick = () => {
    dispatch(setupTarget(target));
    dispatch(findPostalCodeAsync(payloadData));
  };
  const handleSelectPostCode = (kod) => {
    setValue(`${target}.postal_code`, kod, { shouldValidate: true });
    dispatch(clearPostalCodes());
  };

  // console.log(postalCodes);
  return (
    <>
      <SearchLink onClick={handleClick}>Znajdź kod pocztowy</SearchLink>
      {postalCodes && postalCodes.length > 0 && (
        <div>
          {postalCodes.map((postal, index) => (
            <PostalCodeBox
              key={index}
              onClick={() => handleSelectPostCode(postal.kod)}
            >
              {postal.kod}
            </PostalCodeBox>
          ))}
        </div>
      )}
    </>
  );
};

export default PostalCode;
