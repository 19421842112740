import React from "react";
import { Link } from "react-router-dom";
const ShortInfo = () => {
  return (
    <div className="container-fluid links-container ng-scope">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <div className="infos">
              <h2>
                Jak pomaga
                <br /> Twoja darowizna?
              </h2>
              <p>
                Każdy ma szansę pomóc dzieciom, które <br />
                doświadczają krzywdzenia. To proste,{" "}
                <Link to="/jak-pomaga-twoja-darowizna" className="link">
                  zobacz
                </Link>{" "}
                jak
                <br /> pomaga Twoja darowizna.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="infos">
              <h2>
                Najczęściej <br />
                zadawane pytania
              </h2>
              <p>
                Jeżeli masz jakieś wątpliwości,{" "}
                <Link to="/faq" className="link">
                  zajrzyj tutaj
                </Link>
                .<br /> Nie znalazłeś odpowiedzi? Napisz do nas:
                <br />{" "}
                <a className="link" href="mailto:mikolaj@fdds.pl">
                  mikolaj@fdds.pl
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShortInfo;
