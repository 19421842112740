import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "./../assets/images/fdds.png";
import classNames from "classnames";
import { selectEventConfig } from "../features/event_fetcher/EventFetcherSlice";
import { selectLetterId } from "../features/letter_order/letterOrderSlice";
import { useSelector } from "react-redux";

const MenuTop = () => {
  const [hideMenu, setHideMenu] = useState(true);
  const location = useLocation();
  const { event } = useSelector(selectEventConfig);
  const letterId = useSelector(selectLetterId)
  console.log({ selectLetterId })
  useEffect(() => {
    setHideMenu(true);
  }, [location]);

  return (
      <nav className="navbar navbar-default">
        <div className="container-fluid">
          <div className="navbar-headers">
            {event.active && (
                <button
                    type="button"
                    className="navbar-toggle collapsed"
                    onClick={(e) => setHideMenu(!hideMenu)}
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"/>
                  <span className="icon-bar"/>
                  <span className="icon-bar"/>
                </button>
            )}
            <div className="navbar-brand">
              <img src={logo} width={125} alt="Fundacja DDS"/>
            </div>
            {event.active && (
                <>
                  {!letterId && <Link className="wyslij_small" to="/zamow-list">
                    Wyślij list
                  </Link>}

                  <ul
                      className={classNames(
                          "mainnav",
                          "nav",
                          "navbar-nav",
                          "navbar-right",
                          { hiddenMobile: hideMenu }
                      )}
                  >
                    <li>
                      {letterId && <a href="/">Strona główna</a>}
                      {!letterId && <Link to="/">Strona główna</Link>}

                    </li>
                    <li>
                      <Link to="/o-fundacji">O Fundacji</Link>
                    </li>
                    <li>
                      <Link to="/jak-napisac-list">Jak napisać list</Link>
                    </li>
                    <li>
                      <Link to="/jak-pomaga-twoja-darowizna">
                        Jak pomaga twoja darowizna
                      </Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                  </ul>
                </>
            )}
          </div>
        </div>
      </nav>
  );
};

export default MenuTop;
