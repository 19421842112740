import React from "react";
import ReactGA from "react-ga";
import { useSelector } from "react-redux";
import { Counter } from "./features/counter/Counter";

import { EventFetcher } from "./features/event_fetcher/EventFetcher";
import { selectEventConfig } from "./features/event_fetcher/EventFetcherSlice";

import Layout from "./layout/Layout";
import MainPage from "./pages/MainPage/MainPage";
import ThankYou from "./pages/ThankYou/ThankYou";
import LetterOrder from "./pages/LetterOrder/LetterOrder";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/bootstrap-theme.min.css";
import "./assets/css/list_boot.css";
import "./assets/css/list_media.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loading from "react-fullscreen-loading";
import AboutPage from "./pages/AboutPage/AboutPage";
import HowHelp from "./pages/HowHelp/HowHelp";
import HowToWrite from "./pages/HowToWrite/HowToWrite";
import Faq from "./pages/Faq/Faq";

const $ = require("jquery");
window.$ = $;
window.jQuery = $;
require("bootstrap/dist/js/bootstrap.min.js");

ReactGA.initialize('UA-77525895-7', { debug: false })
if (window.location.pathname !== '/zamow-list') {
  ReactGA.pageview(window.location.pathname + window.location.search);
}

function App() {
  const { event } = useSelector(selectEventConfig);
  return (
      <Router>
        <EventFetcher/>
        {event ? (
            <>
              <Layout>
                <Switch>


                  <Route exact path="/">
                    {/*<Redirect from="/" to="/zamow-list"/>*/}
                    {event.active ? (<MainPage/>) : (<div/>)}
                  </Route>
                  <Route exact path="/kupon/:coupon">
                    {event.active ? (<MainPage/>) : (<div/>)}
                  </Route>
                  <Route exact path="/partner/:partner">
                    {event.active ? (<MainPage/>) : (<div/>)}
                  </Route>
                  <Route exact path="/backdoor">
                    <MainPage/>
                  </Route>
                  <Route path="/zamow-list/platnosc/:letterId/:email">
                    {event.active ? (<LetterOrder/>) : (<div/>)}
                  </Route>
                  <Route path="/zamow-list">
                    {event.active ? (<LetterOrder/>) : (<div/>)}
                  </Route>
                  <Route path="/o-fundacji">
                    <AboutPage/>
                  </Route>
                  <Route path="/jak-napisac-list">
                    <HowToWrite/>
                  </Route>
                  <Route path="/jak-pomaga-twoja-darowizna">
                    <HowHelp/>
                  </Route>
                  <Route path="/faq">
                    <Faq/>
                  </Route>
                  <Route path="/dziekujemy/l/:letterId/amt/:amount">
                    <ThankYou/>
                  </Route>

                  <Route path="/counter">
                    <Counter/>
                  </Route>
                </Switch>
              </Layout>

            </>
        ) : (
            <Loading loading background="#ffffff" loaderColor="#102941"/>
        )}
      </Router>
  );
}

export default App;
